<template>
  <v-container>
    <v-row>
      <!-- Source ListBox -->
      <v-col cols="5">
        <v-card class="list-box">
          <v-card-title class="list-title">{{ $t('available_items') }}</v-card-title>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in localAvailableItems"
              :key="index"
              @click="selectItem(item, 'available')"
              :class="{ 'selected-item': selectedAvailableItems.includes(item) }"
            >
              <v-list-item-content>{{ item.description }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <!-- Action Buttons -->
      <v-col cols="2" class="text-center action-buttons">
        <v-btn
          @click="moveToSelected"
          class="mb-2"
          color="primary"
          :disabled="!selectedAvailableItems.length"
          elevation="2"
          block
          large
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          @click="moveToAvailable"
          color="primary"
          :disabled="!selectedSelectedItems.length"
          elevation="2"
          block
          large
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>

      <!-- Destination ListBox -->
      <v-col cols="5">
        <v-card class="list-box">
          <v-card-title class="list-title">{{ $t('selected_items') }}</v-card-title>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in selectedItems"
              :key="index"
              @click="selectItem(item, 'selected')"
              :class="{ 'selected-item': selectedSelectedItems.includes(item) }"
            >
              <v-list-item-content>{{ item.description }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Submit Button -->
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          @click="submitSelection"
          color="primary"
          class="text-white text-bold rounded-0 receipt-btn"
          block
          large
        >
          {{ $t('save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    availableItems: {
      type: Array,
      default: () => [], // Accept available items as a prop
    },
  },
  data() {
    return {
      localAvailableItems: [...this.availableItems], // Create a local copy of the available items
      selectedItems: [], // Items in the destination ListBox
      selectedAvailableItems: [], // Selected items in the source ListBox
      selectedSelectedItems: [], // Selected items in the destination ListBox
    };
  },
  watch: {
    // Watch for changes in the availableItems prop and update the local copy
    availableItems: {
      handler(newItems) {
        this.localAvailableItems = [...newItems];
      },
      deep: true,
    },
  },
  methods: {
    // Function to select an item in either the available or selected list
    selectItem(item, listType) {
      if (listType === "available") {
        const index = this.selectedAvailableItems.indexOf(item);
        if (index > -1) {
          this.selectedAvailableItems.splice(index, 1);
        } else {
          this.selectedAvailableItems.push(item);
        }
      } else if (listType === "selected") {
        const index = this.selectedSelectedItems.indexOf(item);
        if (index > -1) {
          this.selectedSelectedItems.splice(index, 1);
        } else {
          this.selectedSelectedItems.push(item);
        }
      }
    },
    // Move selected items from the available list to the selected list
    moveToSelected() {
      this.selectedItems.push(...this.selectedAvailableItems);
      this.localAvailableItems = this.localAvailableItems.filter(
        (item) => !this.selectedAvailableItems.includes(item)
      );
      this.selectedAvailableItems = [];
    },
    // Move selected items from the selected list back to the available list
    moveToAvailable() {
      this.localAvailableItems.push(...this.selectedSelectedItems);
      this.selectedItems = this.selectedItems.filter(
        (item) => !this.selectedSelectedItems.includes(item)
      );
      this.selectedSelectedItems = [];
    },
    // Submit the selected items
    submitSelection() {
      this.$emit("submit-selected", this.selectedItems); // Emit the selected items to the parent component
    },
  },
};
</script>

<style scoped>
.list-box {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
}

.list-title {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-btn {
  width: 100%;
  padding: 12px 24px;
  height: 100px !important;
}

.selected-item {
  background-color: #e3f2fd;
  border-radius: 4px;
}

.selected-item:hover {
  background-color: #bbdefb;
}

.v-list-item {
  transition: background-color 0.2s ease-in-out;
}
</style>
